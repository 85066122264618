






































import { upLoad, doGetProductList } from '@/api/suggest';
import { Form } from 'element-ui';
import { FileListItem } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';

export default {
  data() {
    return {
      feedbackModel: {
        title: '',
        type: '',
        productId: '',
        description: '',
        fileList: [] as FileListItem[]
      },
      feedbackRules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }, { max: 15, message: '标题请控制在15个字符之内', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        productId: [{ required: true, message: '请选择关联产品', trigger: 'change' }],
        description: [{ required: true, message: '请填写描述信息', trigger: 'blur' }],
        fileList: [{ required: true, type: 'array', trigger: 'blur', message: '请上传图片' }]
      },
      productList: [] as any[]
    }
  },
  methods: {
    getCurrentForm() {
      const feedbackRef: Form | null = this.$refs.feedbackRef as Form | null;
      return new Promise((resolve, reject) => {
        if (feedbackRef) {
          feedbackRef.validate(isValid => {
            if (isValid) {
              const cloneFormModel: any = cloneDeep(this.feedbackModel);
              // cloneFormModel.image = "https://medusa-small-file-1253272780.cos.ap-shanghai.myqcloud.com/official-website/20230616/c0cafa03bbe1460cb98eee054b22fd91.jpg";
              cloneFormModel.image = cloneFormModel.fileList.map(item => item.url).join(',');
              delete cloneFormModel.fileList;
              resolve(cloneFormModel);
            } else {
              reject('valid error')
            }
          })
        } else {
          reject('no form instance')
        }
      })
    },
    handleRemove(file: any) {
      const idx = this.feedbackModel.fileList.findIndex(info => info.name === file.name);
      if (idx > -1) {
        this.feedbackModel.fileList.splice(idx, 1);
      }
    },
    async addPic(file: any) {
      const whiteList = ["image/jpeg", "image/jpg", "image/png"];
      const isLt5M = file.size < 5 * 1024 * 1024;
      if (!whiteList.includes(file.raw.type)) {
        this.$message.error("上传文件只能是 JPG,PNG格式!");
        return;
      }
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return;
      }
      const res = await upLoad({
        file: file.raw,
      });
      this.feedbackModel.fileList.push({
        name: file.name,
        url: res.data,
        status: 'success'
      })
    },
    async initialProductData() {
      const result = await doGetProductList();
      this.productList = result.data
    }
  },
  mounted() {
    this.initialProductData()
  },
}
