


















































import { doGetSuggestList, doPostAddFeedback } from '@/api/suggest';
import AddFeedback from './user-center/AddFeedback.vue'
import dayjs from 'dayjs';

export const typeMap = {
  'NEW_FEATURES': '新特性',
  'FUNCTION_ABNORMAL': '功能异常',
  'SUGGESTION': '建议',
  'OTHER': '其他'
}
export default {
  components: { AddFeedback }, 
  data() {
    return {
      searchOptions: {
        title: ''
      },
      suggestData: [],
      pagination: {
        currentPage: 1,
        total: 0,
        pageSize: 10
      },
      typeMap,
      showDialog: false
    }
  },
  methods: {
    handleSizeChange(pageSize) {
      this.pagination.currentPage = 1;
      this.pagination.pageSize = pageSize;
      this.initialData()
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.initialData()
    },
    handleSearch() {
      this.pagination.currentPage = 1;
      this.initialData()
    },
    async initialData() {
      let total = 0;
      let data = [];
      try {
        const result = await doGetSuggestList({ current: this.pagination.currentPage, size: this.pagination.pageSize, ...this.searchOptions });
        if (result.data) {
          total = result.data.total;
          data = result.data.records.map(item => ({ ...item, createTime: item.createTime && dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss') }));
        }
      } finally {
        this.suggestData = data;
        this.pagination.total = total;
      }
    },
    async confirmFeedBack() {
      const addFeedbackRefs: InstanceType<typeof AddFeedback> | null = this.$refs.addFeedbackRefs as InstanceType<typeof AddFeedback> | null;
      const data = await addFeedbackRefs!.getCurrentForm();
      try {
        const result = await doPostAddFeedback(data);
        this.$message.success(result.msg || '提交建议成功');
        this.showDialog = false;
        this.handleSearch()
      } catch (err) {
        this.$message.error(err as string)
      }
    },
  },
  mounted() {
    this.initialData()
  },
}
