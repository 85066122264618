import api from "@/libs/request";

export const upLoad = (data: any) => {
  const formData = new FormData();
  formData.append("file", data.file);
  return api.post(`/oss/upload`, formData);
};


export const doGetProductList = () => {
  return api.get('/pc/product/list', {})
}

export const doPostAddFeedback = (data: any) => {
  return api.post('/front/feedback', data)
}

// export const doGetFeedback = () => {
//   return api.get('/')
// }

export const feedbackDocument = (data: any) => {
  return api.post('/pc/qaPairs', data)
}

export const doGetSuggestList = (params: any) => {
  return api.get('/pc/product/pageFeedback', params)
}

export const doGetUserCenterSuggestList = (params: any) => {
  return api.get('/front/feedbackList', params)
}

export const doGetSuggestInfo = (id: string) => {
  return api.get(`/pc/feedback/${id}`, {});
}